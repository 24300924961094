.alexPic {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 25.16%;
}

.upcomingText {
  font-size: 36px;
  line-height: 57px;
}

.teamText {
  font-size: 2rem;
  line-height: 57px;
  position: relative;
  /* width: 178px;
  height: 57px; */
  /* margin-left: 129px; */
  margin-top: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  justify-content: center;
}

.Members {
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-right: 30rem;
  padding-left: 30rem;
  /* width: fit-content; */
}
.MemberBox {
  /* background-color: rgba(0, 6, 120, 0.3); */
  width: rem;
  margin-top: 3rem;
  margin-bottom: 4rem;
  margin-left: 3rem;
  margin-right: 3rem;
  max-height: 35rem;
  text-align: center;
  word-wrap: normal;
  /* top: -1px;
  left: -2px; */
  border-radius: 2rem;
  overflow: clip;
}

@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Saira;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: linear-gradient(
    170.99deg,
    #000678 2.98%,
    #dd015b 49.77%,
    #000678 92.64%
  );
  background-size: cover;
  -webkit-background-size: cover;
  min-height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #1b2127;
}

::-webkit-scrollbar-thumb {
  background-color: #5a5a5a;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

.MemberBox {
  position: relative;
  background-color: transparent;
  text-align: center;
  top: 0px;
  left: 0px;
  margin-top: 15px;
}
.MemberNameText {
  color: whitesmoke;
  text-align: center;
  opacity: 1;
}
.MemberPositionText {
  color: whitesmoke;
  text-align: center;
  position: relative;
}

.WholeMemberBox {
  position: relative;
}

.RoundedImage {
  border-radius: 50%;
  background: #c4c4c4;
}

.InfoBox {
  background-color: rgba(0, 6, 120, 0.3);
  width: 23rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 3rem;
  margin-right: 3rem;
  max-height: 35rem;
  text-align: center;
  /* top: -1px;
  left: -2px; */
  border-radius: 2rem;
  overflow: clip;
}
.Members {
  flex: auto;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  padding-right: 12rem;
  padding-left: 12rem;
  /* width: fit-content; */
}
.Text {
  color: rgba(255, 255, 255, 0.9);
  /* padding: .5rem; */
  position: relative;
  /* justify-content: space-evenly; */
  padding-bottom: 1rem;
}

.RoundedInfoImage {
  /* position: absolute; */
  padding-top: 1rem;
  border-radius: 50%;
  left: -50%;
}

.TutorialPicture {
  border-radius: 50px;
  position: static;
  background: #c4c4c4;
}

.TutorialBox {
  color: whitesmoke;
  text-align: center;
  position: absolute;
}

.ProjectTitleHeading {
  /* identical to box height */
  font-size: 36px;
  line-height: 57px;
  color: #ffffff;
}

.LeftProject {
  /* overflow: auto; */
  /* Remove the overflow comment so that we can set the character limit , for now it is just a sliding window */
  display: flex;
  /* background-color: rgb(0, 0, 0, 0.3); */
  width: 1200px;
  height: 500px;
  border-radius: 20px;
  margin-left: 370px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.RightProject {
  display: flex;
  /* background-color: rgb(0, 0, 0, 0.3); */
  width: 1200px;
  height: 500px;
  border-radius: 20px;
  margin-left: 370px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  /* overflow: auto; */
}

.ProjectLeftPic {
  width: 536px;
  height: 402px;
  border-radius: 30px;
  position: sticky;
  background: #c4c4c4;
}

.ProjectRightPic {
  width: 536px;
  height: 402px;
  border-radius: 30px;
  position: sticky;
  background: #c4c4c4;
}

.ProjectTextRight {
  margin-right: 72px;
  font-size: 26px;
  font-weight: 300;
  line-height: 41px;
  color: #c4c4c4;
  text-align: right;
  padding-right: 48px;
  /* width: 536px; */
}

.ProjectTextLeft {
  margin-left: 72px;
  font-size: 26px;
  font-weight: 300;
  line-height: 41px;
  padding-left: 48px;
  color: #c4c4c4;
  text-align: left;
  width: 536px;
}

.Footer {
  bottom: 0;
}

.CopyrightFooter {
  font-size: 1.2rem;
}

.InstagramLogo {
  margin-top: 2rem;
}

.Header {
  margin-top: 100px;
  font-size: 50px;
}
/*
    left: 19.38%;
    right: 19.9%;
    top: 5.87%;
    bottom: 49.62%;
*/

/* use the top and left to move the Member box*/
/*.MemberBox{ FIGMA TEMPLATE CSS

    position: sticky;
    width: 389px;
    height: 529px;
    left: -2px;
    top: -1px;
    
    background: aqua;
    border-radius: 30px;
}*/

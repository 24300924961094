.navbar {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 2.5rem;
  padding-top: 1;
  z-index: 1;
  width: 100%;
  position: fixed;
  background-color: linear-gradient(
    180deg,
    rgba(0, 6, 120, 0.61) 0%,
    rgba(0, 6, 120, 0.6) 27.6%,
    rgba(0, 6, 120, 0.29) 59.37%,
    rgba(0, 6, 120, 0) 100%
  );
  top: 0;
}
.navbar-sticky {
  background: #333;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
}
.navbar-link {
  padding-right: 4rem;
  display: flex;
  padding: auto;
}
.navbar-link-item {
  font-size: large;
  margin: 1rem 1rem 1rem 1rem;
  float: right;
}

a {
  text-decoration: none;
  color: white;
}

.textHeader {
  top: rem;
  font-family: Saira;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 57px;
  position: sticky;
  align-items: flex-start;
}

.joinText {
  color: #1a1b71;
  margin-top: 1rem;
  background-color: #ffffff;
  width: 3rem;
  border-radius: 30px;
  margin: 1rem 1rem 1rem 1rem;
}
@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}
